.contact__box {
  display: inline-block;
  width: 100%;
  margin: 20px 0 0 0;
  border-radius: 8px;
  padding: 20px;
  background-color: #f5f5f5;
  height: 150px;
  vertical-align: middle;
}

@media (min-width: 450px) {
  .contact__box {
    width: 380px;
  }
  .contact__box:nth-of-type(even) {
    margin-right: 40px;
  }
}
