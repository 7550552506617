@import-normalize;

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Rajdhani-Light.ttf");
}
@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rajdhani-Regular.ttf");
}
@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Rajdhani-Medium.ttf");
}
@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Rajdhani-SemiBold.ttf");
}
@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Rajdhani-Bold.ttf");
}

@font-face {
  font-family: Pt-Sans;
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/pt-sans-regular.ttf");
}
@font-face {
  font-family: Pt-Sans;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/pt-sans-700.ttf");
}

body {
  margin: 0;
  font-family: "Rajdhani", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

button {
  font-family: "Rajdhani", sans-serif;
}

* {
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
input.middle:focus {
  outline-width: 0;
}

a {
  text-decoration: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.max-width-row {
  max-width: 1240px;
  width: 100%;
}
img {
  max-width: 100%;
}
.mt-0 {
  margin-top: 0 !important;
}
