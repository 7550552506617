.wysiwyg-dropdown p,
.wysiwyg-dropdown {
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  margin: 0 0 24px 0;
}
.wysiwyg {
  font-size: 16px;
  line-height: 28px;
}

.wysiwyg a {
  color: #4762ab;
  text-decoration: underline;
}

.wysiwyg p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
}

.wysiwyg h2 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  font-weight: 900;
}

.wysiwyg h3 {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 900;
}

.wysiwyg h4 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: 900;
}
.wysiwyg h5 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: 900;
}
.wysiwyg h6 {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  font-weight: 900;
}

.wysiwyg pre {
  margin: 20px 0;
  text-align: center;
  white-space: normal;
  font-size: 16px;
  padding: 20px;
  background-color: #f2f8ff;
  border-radius: 8px;
  font-family: "Rajdhani", sans-serif;
}

.wysiwyg blockquote {
  position: relative;
  padding: 20px 40px;
  margin: 40px auto;
  max-width: 600px;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 8px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.wysiwyg blockquote:before,
.wysiwyg blockquote:after {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background: url("/images/quotes.png");
  background-size: contain;
}

.wysiwyg blockquote:before {
  bottom: 15px;
  left: 10px;
}
.wysiwyg blockquote:after {
  top: 15px;
  right: 10px;
  transform: rotate(180deg);
}

.wysiwyg table,
.wysiwyg th,
.wysiwyg td {
  border: 1px solid #eeeeee;
}
.wysiwyg td {
  padding: 10px;
  text-align: center;
}
.wysiwyg td:first-of-type {
  text-align: left;
}
.wysiwyg table {
  border-collapse: collapse;
  width: 100%;
  margin: 40px 0;
}

.wysiwyg ul {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
}
.wysiwyg ol {
  padding-left: 16px;
  margin-bottom: 40px;
}
.wysiwyg ul li,
.wysiwyg ol li {
  padding-left: 16px;
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  display: table;
}
.wysiwyg ol li {
  padding-left: 3px;
}

.wysiwyg ul li:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border: 1px solid #000;
  top: 8px;
  left: 0;
}

.wysiwyg .button-in-content {
  display: inline-block;
  margin: 40px auto;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1;
  padding: 16px 36px;
  min-width: 200px;
  color: #fff;
  cursor: pointer;
  background-color: #4d75e1;
  background: linear-gradient(152deg, #4d75e1 0%, #4762ab 100%);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}
.wysiwyg .button-in-content:hover {
  opacity: 0.8;
}
@media screen and (max-width: 980) {
  .wysiwyg h2 {
    font-size: 22px;
    line-height: 28px;
  }

  .wysiwyg h3 {
    font-size: 18px;
  }

  .wysiwyg h4 {
    font-size: 16px;
  }
  .wysiwyg h5 {
    font-size: 14px;
    line-height: 22px;
  }
}
