/* .container div {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
} */

.container > div > div[style*="display: block"] ~ span {
  display: block !important;
}
.container > div > div[style*="display: none"] ~ span {
  display: none !important;
}
